@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: Pacifico;
  src: url("./assets/fonts/Pacifico-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url("./assets/fonts/Raleway-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Poppins;
  src: url("./assets/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

html {
  height: 100%;
  width: 100%;
}

body {
  font-family: Poppins, sans-serif;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

#root {
  height: 100%;
  width: 100%;
}

.w-500 {
  width: 500px
}
.h-500 {
  height: 500px
}
.w-430{
  width: 430px;
}
.h-455{
  height:455px
}
.w-600 {
  width: 600px
}
.h-600 {
  height: 600px
}
.mh-600 {
  max-height: 600px
}

.h-620 {
  height: 620px
}
.mh-620 {
  max-height: 620px
}

.w-55rem {
  width:55rem
}
.no-scroll-body {
  overflow: hidden !important;
}


.logo {
  background-image: url("./assets/images/logo.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.anyaya {
  background-image: url("./assets/images/anyaya.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.anyaya-new {
  background-image: url("./assets/images/anyaya-new.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.no-item {
  background-image: url("./assets/images/icons/noitem.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.bell {
  background-image: url("./assets/images/icons/bell.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.banner {
  background-image: url("./assets/images/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-1 {
  background-image: url("./assets/images/banner-1.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}
.mobile-banner {
  background-image: url("./assets/images/mobile-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}
.icon-anyaya {
  background-image: url("./assets/images/icon.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.icon-location {
  background-image: url("./assets/images/icons/location.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.icon-calendar {
  background-image: url("./assets/images/icons/calendar.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.icon-listing {
  background-image: url("./assets/images/icons/listing.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.flexible {
  background-image: url("./assets/images/icons/flexible.png");
}

.luzon {
  background-image: url("./assets/images/icons/luzon.png");
}

.visayas {
  background-image: url("./assets/images/icons/visayas.png");
}

.mindanao {
  background-image: url("./assets/images/icons/mindanao.png");
}

.rating {
  background-image: url("./assets/images/icons/rating.png");
}
.ribbon {
  background-image: url("./assets/images/icons/ribbon.png");
}
.heart {
  background-image: url("./assets/images/icons/heart.png");
}
.verified {
  background-image: url("./assets/images/icons/verified.png");
}

.event {
  background-image: url("./assets/images/icons/event.png");
}


.emptymsg {
  background-image: url("./assets/images/icons/emptymsg.png");
}

.save {
  background-image: url("./assets/images/icons/save.png");
}

.eventexpert {
  background-image: url("./assets/images/icons/eventexpert.png");
}

.recognized {
  background-image: url("./assets/images/icons/recognized.png");
}

.response {
  background-image: url("./assets/images/icons/response.png");
}
.defaulticon {
  background-image: url("./assets/images/icons/defaulticon.png");
}
.defaultimg {
  background-image: url("./assets/images/icons/defaultimg.png");
}
.secured {
  background-image: url("./assets/images/icons/secured.png");
}
.money {
  background-image: url("./assets/images/icons/money.png");
}

.report {
  background-image: url("./assets/images/icons/report.png");
}

.eventtheme {
  background-image: url("./assets/images/icons/eventtheme.png");
}

.timelimit {
  background-image: url("./assets/images/icons/timelimit.png");
}
.servicecap {
  background-image: url("./assets/images/icons/servicecap.png");
}
.user {
  background-image: url("./assets/images/icons/user.png");
}

.check {
  background-image: url("./assets/images/icons/check.png");
}

.personalinfo {
  background-image: url("./assets/images/icons/personalinfo.png");
}

.loginsecurity {
  background-image: url("./assets/images/icons/loginsecurity.png");
}

.notification {
  background-image: url("./assets/images/icons/notification.png");
}

.payments {
  background-image: url("./assets/images/icons/payments.png");
}

.privacysharing {
  background-image: url("./assets/images/icons/privacysharing.png");
}

.merchant {
  background-image: url("./assets/images/merchant.png");
}

.menubg {
  background-image: url("./assets/images/MenuImg2.png");
  background-position-x: center;
}
.maya {
  background-image: url("./assets/images/maya.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.maya-landing {
  background-image: url("./assets/images/maya-landing.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.client {
  background-image: url("./assets/images/guest-landing.png");
}

.allevent {
  background-image: url("./assets/images/icons/filter/allevent.png");
}
.anniversary {
  background-image: url("./assets/images/icons/filter/anniversary.png");
}
.bachelor {
  background-image: url("./assets/images/icons/filter/bachelor.png");
}
.bbq {
  background-image: url("./assets/images/icons/filter/bbq.png");
}
.birthday {
  background-image: url("./assets/images/icons/filter/birthday.png");
}
.christening {
  background-image: url("./assets/images/icons/filter/christening.png");
}
.corporate {
  background-image: url("./assets/images/icons/filter/corporate.png");
}
.pool {
  background-image: url("./assets/images/icons/filter/pool.png");
}
.reunion {
  background-image: url("./assets/images/icons/filter/reunion.png");
}
.wedding {
  background-image: url("./assets/images/icons/filter/wedding.png");
}

.active-allevent {
  background-image: url("./assets/images/icons/filter/activeallevent.png");
}
.active-anniversary {
  background-image: url("./assets/images/icons/filter/activeanniversary.png");
}
.active-bachelor {
  background-image: url("./assets/images/icons/filter/activebachelor.png");
}
.active-bbq {
  background-image: url("./assets/images/icons/filter/activebbq.png");
}
.active-birthday {
  background-image: url("./assets/images/icons/filter/activebirthday.png");
}
.active-christening {
  background-image: url("./assets/images/icons/filter/activechristening.png");
}
.active-corporate {
  background-image: url("./assets/images/icons/filter/activecorporate.png");
}
.active-pool {
  background-image: url("./assets/images/icons/filter/activepool.png");
}
.active-reunion {
  background-image: url("./assets/images/icons/filter/activereunion.png");
}
.active-wedding {
  background-image: url("./assets/images/icons/filter/activewedding.png");
}

.anyaya-bottom {
  background-image: url("./assets/images/anyaya-bottom.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  /* background-position: bottom right; */
}

.rmdp-container {
  width: 100%;
}

:root {
  --rmdp-primary-purple: #604EA0;
  --rmdp-secondary-purple: #6d60a4;
  --rmdp-shadow-purple: #7d72ae;
  --rmdp-today-purple: #8d83b8;
  --rmdp-hover-purple: #aa9fd0;
  --rmdp-deselect-purple: #aa9fd0;
}

.purple .rmdp-wrapper {
  border: 1px solid var(--rmdp-secondary-purple);
  box-shadow: 0 0 5px var(--rmdp-secondary-purple);
}

.purple .rmdp-panel-body li {
  background-color: var(--rmdp-primary-purple);
  box-shadow: 0 0 2px var(--rmdp-secondary-purple);
}

.purple .rmdp-week-day {
  color: var(--rmdp-primary-purple);
}

.purple .rmdp-day.rmdp-deactive {
  color: var(--rmdp-secondary-purple);
}
.rmdp-header-values {
  color: var(--rmdp-primary-purple)!important;
  font-size: 15px!important;
  font-weight: 600;
}
.rmdp-shadow {
  box-shadow: 0 0 0 #8798ad!important
}
.rmdp-range-hover {
  color: #FFF!important;
}
.rmdp-range.start, .rmdp-range.end{
  background-color: var(--rmdp-primary-purple)!important;
  box-shadow: 0 0 0px var(--rmdp-shadow-purple);
  border-radius: 50%;
  color: #FFF!important;
}
.purple .rmdp-range.start.end{
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}

.purple .rmdp-range.start{
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.purple .rmdp-range.end{
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.purple .rmdp-range {
  background-color: rgb(247, 247, 247);
  box-shadow: 0 0 0px var(--rmdp-shadow-purple);
  color: #333333;
}

.rmdp-day span{
  font-size: 15px!important;
}
.rmdp-day:not(.start):not(.end):not(.rmdp-disabled)  span:hover{
  color: #333333!important;
}

.rmdp-range-hover :hover{
  color: #333333!important;
}
.fullwidth{
  width: 100%!important;
  z-index: 0!important;
}
.fullwidth div{
  width: 100%;
}

.mobile .rmdp-day, .mobile .rmdp-week-day{
  height: 43px!important;
  width: 43px!important;
}

.rmdp-day, .rmdp-week-day{
  height: 50px!important;
  width: 50px!important;
}

.purple .rmdp-arrow {
  border: solid var(--rmdp-primary-purple);
  border-width: 0 2px 2px 0;
}

.purple .rmdp-arrow-container:hover {
  background-color: var(--rmdp-primary-purple);
  box-shadow: 0 0 0px var(--rmdp-secondary-purple);
}

.purple .rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--rmdp-primary-purple);
}

.purple .rmdp-day.rmdp-today span {
  background-color: var(--rmdp-today-purple);
}

.purple .rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--rmdp-secondary-purple);
}

.purple .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary-purple);
  box-shadow: 0 0 3px var(--rmdp-shadow-purple);
}

.purple .rmdp-day:not(.rmdp-day-hidden):not(.rmdp-disabled) span:hover {
  background-color: transparent!important;
  border:1px solid var(--rmdp-primary-purple);
}


.purple .b-deselect {
  color: var(--rmdp-deselect-purple);
  background-color: white;
}

.purple .rmdp-action-button {
  color: var(--rmdp-primary-purple);
}

.purple .rmdp-button:not(.rmdp-action-button) {
  background-color: var(--rmdp-primary-purple);
}

.purple .rmdp-button:not(.rmdp-action-button):hover {
  background-color: var(--rmdp-deselect-purple);
}

.line-through span {
  text-decoration: line-through;
}

.rmdp-day.rmdp-disabled span{
  text-decoration: line-through!important;
}


.blocked-date {
  color: #8798ad!important;
  text-decoration: line-through!important;
}

.rmdp-container {
  width: 100%;
}

div .button-date{
  width: 100%;
}
.text-center-webkit {
  text-align: -webkit-center;
}
.text-start-webkit {
  text-align: -webkit-start;
}
.fc .fc-button-primary {
  border-color: var(--rmdp-primary-purple);
  background-color: var(--rmdp-primary-purple);
}
.fc-button-group :hover{
  border-color: var(--rmdp-shadow-purple)!important;
  background-color: var(--rmdp-shadow-purple)!important;
}
.fc-button-primary :hover{
  border-color: var(--rmdp-shadow-purple)!important;
  background-color: var(--rmdp-shadow-purple)!important;
}
.fc-button {
  margin-left:0.5rem!important;
  border-radius: 2rem !important;
  font-size: 0.7em!important;
  padding: 1em!important;
}

.fc-toolbar-title {
  font-size: 1rem!important;
  font-weight:600!important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* confetti.css */
@keyframes confetti {
  0% { background-position: 50% 100%; }
  100% { background-position: 50% -100%; }
}

.confetti-bg {
  background: url('./assets/images/confetti.png');
  background-size: 300%;
  animation: confetti 6s linear infinite;
}


.confetti-top {
  background: url('./assets/images/confetti.png');
  background-size: 400%;
  animation: confetti 8s linear infinite;
}